import axios from "axios";

// -------------------- Constants --------------------

export const CATEGORY_LISTING = "CATEGORY_LISTING";
export const CATEGORY_DETAIL = "CATEGORY_DETAIL";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

export const CATEGORY_ATTRIBUTE_LISTING = "CATEGORY_ATTRIBUTE_LISTING";
export const DELETE_ATTRIBUTE = "DELETE_ATTRIBUTE";
export const ATTRIBUTE_DETAIL = "ATTRIBUTE_DETAIL";

export const CHARACTER_LISTING = "CHARACTER_LISTING";
export const DELETE_CHARACTER = "DELETE_CHARACTER";
export const CHARACTER_DETAIL = "CHARACTER_DETAIL";

// -------------------- Functions --------------------

export const getCategories = (props) => {
  return axios({
    method: 'GET',
    params: props,
    url: "/admin/getCategories",
  });
};

export const addCategory = (props) => {
  return axios({
    method: 'POST',
    data: props,
    url: "/admin/addCategory",
  });
};

export const deleteCategory = (props) => {
  return axios({
    method: 'DELETE',
    data: props,
    url: "/admin/deleteCategory",
  });
};

export const editCategory = (props) => {
  return axios({
    method: 'PATCH',
    data: props,
    url: "/admin/editCategory",
  });
};

export const reorderCategory = (props) => {
  return axios({
    method: 'POST',
    data: props,
    url: "/admin/changeCategoryPosition",
  });
};

export const getCategoryById = (props) => {
  return axios({
    method: 'GET',
    params: props,
    url: "/admin/getCategoryWithId",
  });
};

export const updateStatus = (props) => {
  return axios({
    method: 'PATCH',
    data: props,
    url: "/admin/updateStatus",
  });
};

export const getCategoryAttributes = (props) => {
  return axios({
    method: 'GET',
    params: props,
    url: "/admin/getCategoryAttributes",
  });
};

export const addCategoryAttribute = (props) => {
  return axios({
    method: 'POST',
    data: props,
    url: "/admin/addCategoryAttribute",
  });
};

export const deleteCategoryAttribute = (props) => {
  return axios({
    method: 'DELETE',
    data: props,
    url: "/admin/deleteCategoryAttribute",
  });
};

export const editCategoryAttribute = (props) => {
  return axios({
    method: 'POST',
    data: props,
    url: "/admin/editCategoryAttribute",
  });
};

export const reorderCategoryAttribute = (props) => {
  return axios({
    method: 'POST',
    data: props,
    url: "/admin/changeCategoryAttributePosition",
  });
};

export const getAttributeById = (props) => {
  return axios({
    method: 'GET',
    params: props,
    url: "/admin/getCategoryAttributeWithId",
  });
};






export const getCharacters = (props) => {
  return axios({
    method: 'GET',
    params: props,
    url: "/admin/getCharacters",
  });
};

export const addCharacter = (props) => {
  return axios({
    method: 'POST',
    data: props,
    url: "/admin/addCharacter",
  });
};

export const deleteCharacter = (props) => {
  return axios({
    method: 'DELETE',
    data: props,
    url: "/admin/deleteCharacter",
  });
};

export const editCharacter = (props) => {
  return axios({
    method: 'PATCH',
    data: props,
    url: "/admin/editCharacter",
  });
};

export const reorderCharacter = (props) => {
  return axios({
    method: 'POST',
    data: props,
    url: "/admin/changeCharacterPosition",
  });
};

export const getCharacterById = (props) => {
  return axios({
    method: 'GET',
    params: props,
    url: "/admin/getCharacterWithId",
  });
};







