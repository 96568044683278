import { USERS, DELETE_USERS } from "../actions/user";

const initialState = {
  userListing: null
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case USERS:
      return {
        ...state,
        userListing: action.payload
      };
    break;
    
    case DELETE_USERS:
      const data = { ...state.userListing };
      let index = data?.data?.findIndex(item => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        userListing: data
      };
      break;
    default:
  }
  return state;
}
export default user;