import React from 'react';
import { Redirect } from 'react-router-dom';

// Component imports
import { load } from './utilities';


export const ROUTES = {
  ROOT: {
    path: '/',
    exact: true,
    render: () => <Redirect to="/login" />
  },
  LOGIN: {
    path: '/login',
    exact: true,
    component: load('Login/Login')
  },
  LOGOUT: {
    path: '/logout',
    component: load('Logout')
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    exact: true,
    component: load('ForgotPassword/ForgotPassword'),
  },
  RESET_OR_CHANGE_PASSWORD: {
    path: '/update-password/:token',
    exact: true,
    component: load('ResetOrChangePassword/ResetOrChange'),
  },

  DASHBOARD: {
    path: '/dashboard',
    component: load('Layout/Layout'),
  },
  DASHBOARD_ANALYTICS: {
    path: '/dashboard',
    exact: true,
    component: load('Dashboard/Dashboard'),
  },

  GENERAL_SETTINGS: {
    path: '/dashboard/general-settings',
    exact: true,
    component: load('GeneralSettings/GeneralSettings')
  },

  USERS: {
    path: '/dashboard/users',
    exact: true,
    component: load('Users/Listing')
  },

  CHARACTERS: {
    path: '/dashboard/characters',
    exact: true,
    component: load('Arts/CharacterListing')
  },
  ADD_CHARACTERS: {
    path: '/dashboard/characters/add',
    exact: true,
    component: load('Arts/AddCharacter')
  },
  EDIT_CHARACTERS: {
    path: '/dashboard/characters/edit/:id',
    exact: true,
    component: load('Arts/EditCharacter')
  },
  CATEGORIES: {
    path: '/dashboard/characters/categories/:characterId',
    exact: true,
    component: load('Arts/Listing')
  },
  ADD_CATEGORIES: {
    path: '/dashboard/characters/categories/add/:characterId',
    exact: true,
    component: load('Arts/Add')
  },
  EDIT_CATEGORIES: {
    path: '/dashboard/characters/categories/edit/:characterId/:id',
    exact: true,
    component: load('Arts/Edit')
  },

  CATEGORIES_ATTRIBUTE: {
    path: '/dashboard/categories/attributes/:categoryId',
    exact: true,
    component: load('Arts/AttributeListing')
  },
  ADD_CATEGORIES_ATTRIBUTE: {
    path: '/dashboard/categories/attributes/add/:categoryId',
    exact: true,
    component: load('Arts/AddAttribute')
  },
  EDIT_CATEGORIES_ATTRIBUTE: {
    path: '/dashboard/categories/attributes/edit/:categoryId/:id',
    exact: true,
    component: load('Arts/EditAttribute')
  },


};
