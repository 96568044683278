import axios from "axios";

// -------------------- Constants --------------------
export const SETTING_DETAIL ='SETTING_DETAIL';

// -------------------- Functions --------------------

export function attachmentUpload(props) {
  return axios({
    method: 'POST',
    data: props,
    url: `/attachment/upload`
  })
};

export function attachmentDelete(props) {
  return axios({
    method: 'DELETE',
    params: props,
    url: `/attachment/delete`
  })
};

export function getSettings(props) {
  return axios({
    method: 'GET',
    params: props,
    url: `/admin/getGeneralSettings`
  })
};

export function updateSettings(props) {
  return axios({
    method: 'PATCH',
    data: props,
    url: `/settings/update`
  })
};



