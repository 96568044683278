import { createStore } from 'redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Component imports
import rootReducer from './reducers';
import { BASE_URL } from '../constants';
import { clearSession } from '../utilities';


// creating and persisting redux store
const persistConfig = { key: 'root', storage };

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
// - - - - - - - - - - - - 

// axios interceptor request
axios.interceptors.request.use(request => {
  let accessToken = typeof window !== "undefined" ? window.localStorage.getItem("jwtToken") : null;
  let UTCOffset = new Date().getTimezoneOffset();
  UTCOffset = -(UTCOffset);

  request.headers.common['authorization'] = accessToken;
  request.headers.common['language'] = 'en';
  request.headers.common['utcoffset'] = UTCOffset;
  request.baseURL = BASE_URL;
  return request;
}, error => {
  return Promise.reject(error);
});

// axios interceptor response
axios.interceptors.response.use(response => {
  return response;
}, error => {
  switch (error.response.status) {
    case 401:
      clearSession();
      break;
    case 400:
      toast.error(error.response.data.message);
      break;
    default:
      toast.error('Something went wrong, please try again');
  }
  return Promise.reject(error);
});