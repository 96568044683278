import { lazy } from "react";

export function load(component) {
  return lazy(() => import(`./pages/${component}`));
};

export function clearSession() {
  localStorage.clear();
  // redirect on root
  window.location = '/';
};

export function authentication() {
  // With auth token
  if (typeof localStorage.getItem('jwtToken') !== 'undefined' && localStorage.getItem('jwtToken') !== null)
    return true;
  else
    return false;
};

export function getAclChecks(permissionType) {
  // const Permissions = JSON.parse(localStorage.getItem('Permissions'));
  // var result = false;
  // if (Permissions?.includes('admin')) {
  //   result = true;
  // } else {
  //   Permissions?.length > 0 && Permissions?.map((obj) => (
  //     permissionType === obj ? result = true : result = false
  //   ))
  // }
  return true;
};

export function removeEmptyFields(data) {
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] == null || data[key]?.length === 0 || data[key] === false) {
      delete data[key];
    }
  });
  return data;
};

export function setRetainFilters(filters = {}, page = null){
  if(!page)
    return;

  sessionStorage.setItem(page, JSON.stringify(filters));
}

export function getRetainFilters(page = null){
  if(!page)
    return;

  const value = sessionStorage.getItem(page);
  return value ? JSON.parse(value) : {};
}