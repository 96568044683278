import axios from "axios";

// -------------------- Constants --------------------

export const USERS = "USERS";
export const DELETE_USERS = "DELETE_USERS";
// -------------------- Functions --------------------

export const getAdminUsers = (props) => {
  return axios({
    method: 'GET',
    params: props,
    url: "/admin/user-listing",
  });
};

export const updateStatus = (props) => {
  return axios({
    method: 'PATCH',
    data: props,
    url: "/user/updateStatus",
  });
};