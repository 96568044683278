import { CATEGORY_LISTING, DELETE_CATEGORY, CATEGORY_DETAIL, CATEGORY_ATTRIBUTE_LISTING, DELETE_ATTRIBUTE, ATTRIBUTE_DETAIL, CHARACTER_LISTING, DELETE_CHARACTER, CHARACTER_DETAIL } from "../actions/art";

const initialState = {
  categoryListing: null,
  categoryDetail: null,
  attributeListing: null,
  attributeDetail: null,
  characterListing: null,
  charcterDetail: null
};

const art = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_LISTING:
      return {
        ...state,
        categoryListing: action.payload
      };
      break;
    case DELETE_CATEGORY:
      const data = {...state.categoryListing};
      let index = data?.data?.findIndex(item => item.id === action.id);
      data?.data?.splice(index, 1);
      return {
        ...state,
        categoryListing: data
      };
      break;
    case CATEGORY_DETAIL:
      return {
        ...state,
        categoryDetail: action.payload
      };
      break;
    case CATEGORY_ATTRIBUTE_LISTING:
      return {
        ...state,
        attributeListing: action.payload
      };
      break;
    case DELETE_ATTRIBUTE:
      const attr = [...state.attributeListing];
      let undex = attr?.findIndex(item => item.id === action.id);
      attr?.splice(undex, 1);
      return {
        ...state,
        attributeListing: attr
      };
      break;
    case ATTRIBUTE_DETAIL:
      return {
        ...state,
        attributeDetail: action.payload
      };
      break;

    case CHARACTER_LISTING:
      return {
        ...state,
        characterListing: action.payload
      };
      break;
    case DELETE_CHARACTER:
      const chartr = [...state.characterListing];
      let cndex = chartr?.findIndex(item => item.id === action.id);
      chartr?.splice(cndex, 1);
      return {
        ...state,
        characterListing: chartr
      };
      break;
    case CHARACTER_DETAIL:
      return {
        ...state,
        charcterDetail: action.payload
      };

      break;
    default:
  }
  return state;
}
export default art;