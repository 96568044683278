// ---------------------- Development ---------------------
export const BASE_URL = "https://api.oodles.art";

// export const BASE_URL = "http://54.176.169.179:3060";

// ---------------------- Field validations ---------------------
export const errorType = ["manual", "required", "pattern", "validate", "minLength", "maxLength", "max", "min"];

// ---------------------- File pond ---------------------
export const PER_FILE_SIZE = "5MB";
export const AUDIO_FILE_SIZE = "100MB";

// ---------------------- Common ---------------------
export const limit = 20;
export const dateFormat = 'DD ddd, MMM yy';

export const BLOCK_TYPE = [
  { value: 1, label: 'Banner' },
  { value: 2, label: 'List' }
];

export const TAX_AMOUNT_TYPE = [
  { value: 1, label: 'FIXED' },
  { value: 2, label: 'PERCENTAGE' }
];

export const RAZORPAY_PERIOD = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' }
];

export const CURRENCY = [
	{value: 'INR', label: 'INR'}
];


export const ORDER_STATUS = [
  { value: 5, label: 'INITIATED' },
  { value: 7, label: 'ACCEPT' },
  { value: 8, label: 'INPROGRESS' },
  { value: 10, label: 'READY' },
  { value: 15, label: 'DELIVERED' },
  { value: 20, label: 'USER CANCELLED' },
  { value: 21, label: 'ADMIN CANCELLED' }
];

export const PAYMENT_MODE = [
  { value: 1, label: 'ONLINE' },
  { value: 2, label: 'OFFLINE' }
];

export const ORDER_PAYMENT_STATUS = [
  { value: 1, label: 'FAILED' },
  { value: 2, label: 'PAID' },
  { value: 3, label: 'CREATED' },
  { value: 4, label: 'ATTEMPTED' },
  { value: 5, label: 'REFUND' },
];

export const ANALYTICS_DAYS_FILTER = [
  { value: 7, label: '7 days' },
  { value: 30, label: '30 days' },
  { value: 365, label: '365 days' }
];

export const BLOCK_PAYMENT_STATUS = [
  { value: 0, label: 'Free' },
  { value: 1, label: 'Paid' }
];

export const GUEST_USER = [
  { value: 1, label: 'Chef' },
  { value: 2, label: 'Dietician' }
];

export const MODE_OF_INTERACTION = [
  { value: 1, label: 'WA Email Chat' },
  { value: 2, label: 'Audio Video Call' },
  { value: 3, label: 'Clinic Visit' }
];

export const EDUCATION = [
  { value: 1, label: 'Basic' },
  { value: 2, label: 'Specialised' }
];

export const SERVICE_OFFERED = [
  { value: 1, label: 'Basic Dietary' },
  { value: 2, label: 'Full Meal Plan' },
  { value: 3, label: 'Full Health Plan' }
];

export const SPECIALIZED_SERVICES = [
  { value: 1, label: 'Fitness' },
  { value: 2, label: 'Nutrition Based Food Fitness Regime' },
  { value: 3, label: 'Full Suit Of Health Services' }
];

export const INTEREST = [
  { value: 1, label: 'Part Time' },
  { value: 2, label: 'Full Time' }
];

export const WORKING_HOURS = [
  { value: 1, label: 'Fixed' },
  { value: 2, label: 'Variable' },
  { value: 3, label: 'On Demand' }
];
export const MODE_OF_ENGAGEMENT = [
  { value: 1, label: 'Employment' },
  { value: 2, label: 'Contract' },
  { value: 3, label: 'Bases' }
];
export const CURRENT_EMPLOYEMENT = [
  { value: 1, label: 'Employed' },
  { value: 2, label: 'Self Employed' },
  { value: 3, label: 'Online Platform' }
];

export const RESTAURANT_MODE_OF_INTERACTION = [
  { value: 1, label: 'WA Email Chat' },
  { value: 2, label: 'Audio Video Call' },
  { value: 3, label: 'Clinic Visit' }
];
export const OFFERING = [
  { value: 1, label: 'Plan Discount' },
  { value: 2, label: 'Pricing' },
  { value: 3, label: 'Unique Dish' }
];
export const TYPE_OF_SERVICES = [
  { value: 1, label: 'Dining' },
  { value: 2, label: 'TakeAway' },
  { value: 3, label: 'Registered LMD' }
];
export const SITTING_OPTIONS = [
  { value: 1, label: 'InDoor' },
  { value: 2, label: 'Party' },
  { value: 2, label: 'Open Area' }
];

export const MUSIC = [
  { value: 1, label: 'Piped' },
  { value: 2, label: 'LiveBand' },
  { value: 3, label: 'Stage' }
];
export const SERVICE_TYPE = [
  { value: 1, label: 'Booking' },
  { value: 2, label: 'WalkIN' },
  { value: 3, label: 'BOTH' }
];

export const TYPE = [
  { value: 1, label: 'Fine Dining' },
  { value: 2, label: 'Casual Sitting' },
  { value: 3, label: 'Fast Food Self Service' }
];

export const CULINARY_AREA_OF_INTEREST = [
  { value: 1, label: 'Indian' },
  { value: 2, label: 'Asian' },
  { value: 2, label: 'Chinese' },
  { value: 1, label: 'Thai' },
  { value: 2, label: 'MiddleEast' },
  { value: 2, label: 'MidIterranean' },
  { value: 1, label: 'Maxican' },
  { value: 2, label: 'French' },
  { value: 2, label: 'Others' }
];

export const SPECIALISATION = [
  { value: 1, label: 'Chef' },
  { value: 2, label: 'Baker' },
  { value: 2, label: 'Other' }
];
export const INTEREST_CHEF = [
  { value: 1, label: 'Chef Service' },
  { value: 2, label: 'In Studio' }
];

export const CHEF_WORKING_HOURS = [
  { value: 1, label: 'Fixed' },
  { value: 2, label: 'Variable' }
];
export const CHEF_MODE_OF_ENGAGEMENT = [
  { value: 1, label: 'Employment' },
  { value: 2, label: 'Contract' }
];
export const CHEF_CURRENT_EMPLOYEMENT = [
  { value: 1, label: 'Employed' },
  { value: 2, label: 'Freelance' }
];

export const PLAN_IS_BUYABLE = [
  { value: 0, label: 'No' },
  { value: 1, label: 'Yes' }
];

export const OPENING_CLOSING_DAY = [
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '7', label: 'Sunday' },
  { value: '8', label: 'Monday-Friday' },
  { value: '9', label: 'Saturday-Sunday' },
  { value: '10', label: 'Everyday' }
];

