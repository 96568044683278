import { Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// modules css
import 'react-toastify/dist/ReactToastify.css';

// Component imports
import { ROUTES } from './routes';


function App() {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div className="initial-loader" />}>
          <Switch>
            <Route {...ROUTES.ROOT} />
            <Route {...ROUTES.DASHBOARD} />
            <Route {...ROUTES.LOGIN} />
            <Route {...ROUTES.FORGOT_PASSWORD} />
            <Route {...ROUTES.RESET_OR_CHANGE_PASSWORD} />
            <Route {...ROUTES.LOGOUT} />
            <Route {...ROUTES.BLOG_PREVIEW} />
            <Route {...ROUTES.NOTFOUND} />
          </Switch>
        </Suspense>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
