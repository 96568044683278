import { SETTING_DETAIL } from "../actions/common";

const initialState = {
  settingDetail: null
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case SETTING_DETAIL:
      return {
        ...state,
        settingDetail: action.payload
      };
    default:
  }
  return state;
}
export default common;